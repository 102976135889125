import { FishingReport, Media, SPECIES_NAME_LARGEMOUTH } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useExperiment } from 'statsig-react';
import { isDone, isNotStarted, isPending, LoadingState } from '../../constants/loading_state';
import { useIntersectionObserver } from '../../hooks/use_intersection_observer';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { apiV1 } from '../../lib/api';
import { getDistanceBetweenTwoPoints } from '../../lib/distance_between_two_points';
import { OmniaQueryParams } from '../../lib/query_string';
import { waitFor } from '../../lib/wait_for';
import { WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import {
  getIpCurrentSeasonGroup,
  getIpLatitude,
  getIpLongitude,
  getIpState,
  getLoadingState as getIpLoadingState,
} from '../../redux/geographic_location';
import {
  getSeasonGroups,
  getSpecies,
  getSpeciesTop,
  getZoneCurrentSeasonGroups,
} from '../../redux/reference_data';
import { getLoadingState, getUser } from '../../redux/user';
import { getAmbassadors } from '../../redux/user_profile';
import { RoutePaths } from '../../routes';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { HomeUserDashboard } from '../home_user_dashboard/home_user_dashboard';
import Loading from '../loading/loading';
import { MediaCard } from '../media_card/media_card';
import { MediaFishingReportsList } from '../media_fishing_reports/media_fishing_reports_list';
import { SeasonSpeciesSelects } from '../season_species_selects/season_species_selects';
import { USStatesMapSelect } from '../states_list/us_states_map_select';
import { UserProfileCard } from '../user_profile_card/user_profile_card';
import {
  SVGBackgroundClipPathDesktop,
  SVGBackgroundClipPathMobile,
} from '../waterbody_detail/images/background_clip_paths';
import { CTABanners } from './cta_banners';
import { HeroLoggedOut } from './hero_logged_out';
import styles from './home.less';
import { HomeCarouselLocationSelects } from './home_location_selects';
import { HomeOmniaPro } from './home_omnia_pro';
import { HomePromoPanels } from './home_promo_panels';
import { HomePromoVideos } from './home_promo_videos';
import { LoadingCardCarousel } from './loading_card_carousel';
import { MapComparePro } from './map_compare_pro';
import { PopularNearYou } from './popular_near_you';
import { RecentTrendingProductReports } from './recent_trending_product_reports';
import { RecentTrendingProductReportsOnYourLakes } from './recent_trending_product_reports_on_your_lakes';
interface HomeProps {
  media: Media[];
}

interface FishingReportWithDistanceAndUnifiedReportDate extends FishingReport {
  distance: number;
  reportDate: string;
}

export interface HomeQueryParams {
  [OmniaQueryParams.species]: string;
  [OmniaQueryParams.season_group]: string;
  trending_order:
    | 'most_recent'
    | 'nearest'
    | 'distance_recency_blend'
    | 'distance_recency_blend_d1_r1'
    | 'distance_recency_blend_d3_r2'
    | 'distance_recency_blend_d2_r3'
    | 'distance_recency_blend_d1_r2'
    | 'distance_recency_blend_d2_r1';
  lat: number;
  lng: number;
  show_location_selects: boolean;
  show_slider_map: boolean;
}

export const isLoading = (loadingState: LoadingState) => {
  return isNotStarted(loadingState) || isPending(loadingState);
};

export const Home = (props: HomeProps) => {
  const { media } = props;
  const allSpecies = useSelector(getSpecies);
  const ambassadors = useSelector(getAmbassadors);
  const ipCurrentSeasonGroup = useSelector(getIpCurrentSeasonGroup);
  const ipLoadingState = useSelector(getIpLoadingState);
  const ipLat = useSelector(getIpLatitude);
  const ipLng = useSelector(getIpLongitude);
  const ipState = useSelector(getIpState);
  const isLoggedIn = useSelector(hasAccessToken);
  const seasonGroups = useSelector(getSeasonGroups);
  const topSpecies = useSelector(getSpeciesTop);
  const user = useSelector(getUser);
  const userLoadingState = useSelector(getLoadingState);
  const { userWaterbodies } = useUserWaterbodies();
  const { isDesktop, isMobile } = useResponsive();

  const {
    setUserPreferencesSeasonGroup,
    userPreferencesSeasonGroup,
    userPreferencesSpecies,
    userPreferencesWaterbodies,
  } = useUserPreferences();

  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const currentQuery = getCurrentQuery<HomeQueryParams>();
  const zone2CurrentSeasonGroup = useSelector(getZoneCurrentSeasonGroups)[2];
  const defaultSeasonGroupName =
    currentQuery[OmniaQueryParams.season_group] ||
    userPreferencesSeasonGroup ||
    zone2CurrentSeasonGroup?.name;
  const [selectedSeasonGroupName, setSelectedSeasonGroupName] = useState(
    defaultSeasonGroupName as string
  );

  const defaultUserPrefWaterbody = userPreferencesWaterbodies[0];

  const defaultWaterbodySpeciesNames = defaultUserPrefWaterbody?.species?.map((s) => s.name) || [];
  const getStartedSpeciesName = defaultWaterbodySpeciesNames.includes(
    currentQuery[OmniaQueryParams.species]
  )
    ? currentQuery[OmniaQueryParams.species]
    : defaultWaterbodySpeciesNames.includes(userPreferencesSpecies[0])
    ? userPreferencesSpecies[0]
    : defaultWaterbodySpeciesNames[0] || SPECIES_NAME_LARGEMOUTH;
  const [selectedSpeciesName, setSelectedSpeciesName] = useState(getStartedSpeciesName as string);
  const selectedSpecies = allSpecies.find((s) => s.name === selectedSpeciesName);

  const defaultShowSliderMap = !!currentQuery.show_slider_map ?? false;
  const showSliderMap = useExperiment('home-slider-map').config.get<boolean>(
    'show_slider_map',
    defaultShowSliderMap
  );

  useEffect(() => {
    if (ipCurrentSeasonGroup && !selectedSeasonGroupName) {
      setSelectedSeasonGroupName(ipCurrentSeasonGroup.name);
      replaceQueryString<HomeQueryParams>({
        [OmniaQueryParams.season_group]: ipCurrentSeasonGroup.name,
      });
      setUserPreferencesSeasonGroup(ipCurrentSeasonGroup.name);
    }
  }, [ipCurrentSeasonGroup]);

  useEffect(() => {
    WebAnalytics.fishingIntentChange({
      stateAbbreviation: ipState,
      speciesName: selectedSpeciesName,
      seasonGroupName: selectedSeasonGroupName,
    });
  }, [selectedSpeciesName, selectedSeasonGroupName, ipState]);

  const mediaSliced = media.slice(0, isMobile ? 2 : 4);

  const [videoSectionRef, videoSectionIntersecting] = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  const [recentFishingReports, setRecentFishingReports] = useState([] as FishingReport[]);
  const [recentMediaFishingReports, setRecentMediaFishingReports] = useState([] as FishingReport[]);
  const [recentUserWaterbodyFishingReports, setRecentUserWaterbodyFishingReports] = useState(
    [] as FishingReport[]
  );
  const [recentReportsLoadingState, setRecentReportsLoadingState] = useState(
    LoadingState.NOT_STARTED
  );
  const [recentUserWaterbodyReportsLoadingState, setRecentUserWaterbodyReportsLoadingState] =
    useState(LoadingState.NOT_STARTED);

  const order = currentQuery.trending_order || 'most_recent';

  const fetchRecentReports = async () => {
    setRecentReportsLoadingState(LoadingState.PENDING);
    let order_val = order;
    let weight_distance = 1;
    let weight_recency = 2;
    if (
      order_val === 'distance_recency_blend_d1_r1' ||
      order_val === 'distance_recency_blend_d3_r2' ||
      order_val === 'distance_recency_blend_d2_r3' ||
      order_val === 'distance_recency_blend_d1_r2' ||
      order_val === 'distance_recency_blend_d2_r1'
    ) {
      weight_distance = parseFloat(order_val.split('_')[3].replace('d', ''));
      weight_recency = parseFloat(order_val.split('_')[4].replace('r', ''));
      order_val = 'distance_recency_blend';
    }

    const promises = [
      apiV1.forYouTrendingFishingReports({
        lat: currentQuery.lat || ipLat,
        lng: currentQuery.lng || ipLng,
        order: order_val,
        weight_distance,
        weight_recency,
      }),
      apiV1.forYouTrendingMediaFishingReports({
        lat: currentQuery.lat || ipLat,
        lng: currentQuery.lng || ipLng,
      }),
    ];

    const fetchResults = await Promise.allSettled(promises);
    const recentReportsResult = fetchResults[0];
    const recentMediaReportsResult = fetchResults[1];

    if (recentReportsResult.status === 'fulfilled') {
      setRecentFishingReports(recentReportsResult.value.data.data.fishing_reports);
    }

    if (recentMediaReportsResult.status === 'fulfilled') {
      const mediaFishingReports = recentMediaReportsResult.value.data.data.fishing_reports;
      if (mediaFishingReports.length > 0) {
        const ipCoordinates = { lat: ipLat, lng: ipLng };
        const mediaReportsWithDistanceAndReportDate: FishingReportWithDistanceAndUnifiedReportDate[] =
          mediaFishingReports.map((r) => {
            const distanceBetweenIpAndWaterbody = getDistanceBetweenTwoPoints(ipCoordinates, {
              lat: r.waterbody.lat,
              lng: r.waterbody.lng,
            });
            return {
              ...r,
              distance: distanceBetweenIpAndWaterbody,
              reportDate: dayjs(r.outing_date || r.accepted_at).format('MM/DD/YYYY'),
            };
          });

        const mediaReportsSortedByRatingRecencyDistance = _.orderBy(
          mediaReportsWithDistanceAndReportDate,
          [
            (item) => (item.img_quality_rating === null ? -Infinity : item.img_quality_rating),
            'reportDate',
            'distance',
          ],
          ['desc', 'desc', 'asc']
        );

        setRecentMediaFishingReports(mediaReportsSortedByRatingRecencyDistance);
      }
    }

    setRecentReportsLoadingState(LoadingState.DONE);
  };

  useEffect(() => {
    if ((ipLat && ipLng) || (currentQuery.lat && currentQuery.lng)) {
      fetchRecentReports();
    }
    if (
      Number.isNaN(ipLat) ||
      Number.isNaN(ipLng) ||
      (isDone(ipLoadingState) && !ipLat && !ipLng)
    ) {
      setRecentReportsLoadingState(LoadingState.DONE);
    }
  }, [
    ipLat,
    ipLng,
    currentQuery.trending_order,
    currentQuery.lat,
    currentQuery.lng,
    ipLoadingState,
  ]);

  useEffect(() => {
    if (userWaterbodies.length > 0 && ipLat && ipLng) {
      apiV1.forYouTrendingYourLakes({ lat: ipLat, lng: ipLng }).then((res) => {
        setRecentUserWaterbodyReportsLoadingState(LoadingState.PENDING);
        const recentReports = res.data.data.fishing_reports;
        setRecentUserWaterbodyFishingReports(recentReports);
        setRecentUserWaterbodyReportsLoadingState(LoadingState.DONE);
      });
    }
    if (
      Number.isNaN(ipLat) ||
      Number.isNaN(ipLng) ||
      (isDone(ipLoadingState) && !ipLat && !ipLng)
    ) {
      setRecentUserWaterbodyReportsLoadingState(LoadingState.DONE);
    }
  }, [userWaterbodies, ipLat, ipLng, ipLoadingState]);

  const StateBestSellersSection = (
    <div className={styles.popularSection}>
      <div className={styles.sectionHeader}>
        <h3 className={styles.h3}>Popular Products Near You</h3>
        <div className={styles.seasonSpeciesWrapper}>
          {selectedSeasonGroupName && (
            <SeasonSpeciesSelects
              seasonGroups={seasonGroups}
              speciesOptions={topSpecies}
              selectedSeasonGroup={
                seasonGroups.find((sg) => sg.name === selectedSeasonGroupName) ?? null
              }
              selectedSpecies={selectedSpecies}
              currentSeasonGroup={ipCurrentSeasonGroup ?? null}
              onSpeciesChange={(speciesName) => setSelectedSpeciesName(speciesName)}
              onSeasonGroupChange={(seasonGroupName) => setSelectedSeasonGroupName(seasonGroupName)}
              showSelectsNames={false}
              displayIconsOnMobile
            />
          )}
        </div>
      </div>
      <div className={styles.forYouWrapper}>
        <PopularNearYou
          selectedSeasonGroup={selectedSeasonGroupName}
          selectedSpecies={selectedSpeciesName}
        />
      </div>
    </div>
  );

  const showRecentTrendingCarousel =
    (isDesktop && recentFishingReports.length > 5) || (isMobile && recentFishingReports.length > 1);

  const showRecentOnYourLakesCarousel =
    (isDesktop && recentUserWaterbodyFishingReports.length > 5) ||
    (isMobile && recentUserWaterbodyFishingReports.length > 1);

  const showRecentMediaCarousel =
    (isDesktop && recentMediaFishingReports.length > 3) ||
    (isMobile && recentMediaFishingReports.length > 0);

  useEffect(() => {
    if (isLoggedIn) {
      waitFor(() => window.hasOwnProperty('clarity'), 10_000)
        .then(() => {
          (window as any).clarity('set', 'auth_status', 'logged_in');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isLoggedIn]);

  return (
    <>
      <section
        className={classNames(styles.heroWrapper, {
          [styles.heroWrapper__loggedIn]: isLoggedIn,
        })}
      >
        {isPending(userLoadingState) ? (
          <Loading
            text="Getting your information"
            color="#96b0b9"
            className={styles.dashboardLoading}
          />
        ) : user ? (
          <HomeUserDashboard user={user} />
        ) : (
          <>
            <HeroLoggedOut />
            {showSliderMap && (
              <section className="my-8">
                <ContentWrapper>
                  <MapComparePro ipState={ipState} />
                </ContentWrapper>
              </section>
            )}
          </>
        )}
      </section>
      {isLoggedIn && <CTABanners />}
      <section>
        <ContentWrapper>
          <>
            {isLoading(recentReportsLoadingState) ? (
              <LoadingCardCarousel
                numberOfCards={6}
                wrapperClassName={styles.sectionHeader__new}
                listItemClassName={styles.recentProductReportCard}
                buttonActions={[`View ${isDesktop ? 'Fishing' : ''} Report`, 'Add To Cart']}
                sectionTitle="Popular & Trending Near You"
                placeholderWidth={200}
              />
            ) : showRecentTrendingCarousel ? (
              <div className={styles.sectionHeader__new}>
                <h3 className={styles.h3}>Popular & Trending Near You</h3>
                <RecentTrendingProductReports fishingReports={recentFishingReports} />
              </div>
            ) : (
              StateBestSellersSection
            )}
            {isLoggedIn && isLoading(recentUserWaterbodyReportsLoadingState) ? (
              <LoadingCardCarousel
                numberOfCards={6}
                wrapperClassName={styles.sectionHeader__new}
                listItemClassName={styles.recentProductReportCard}
                buttonActions={[`View ${isDesktop ? 'Fishing' : ''} Report`, 'Add To Cart']}
                sectionTitle="Popular & Trending on Your Lakes"
                placeholderWidth={200}
              />
            ) : (
              showRecentOnYourLakesCarousel && (
                <div className={styles.sectionHeader__yourLakes}>
                  <h3 className={styles.h3}>Popular & Trending on Your Lakes</h3>
                  <RecentTrendingProductReportsOnYourLakes
                    fishingReports={recentUserWaterbodyFishingReports}
                  />
                </div>
              )
            )}
            <HomeCarouselLocationSelects />
            {isLoading(recentReportsLoadingState) ? (
              <LoadingCardCarousel
                blockButtons={false}
                loadingText="Finding Fishing Reports"
                numberOfCards={4}
                placeholderHeight={508}
                placeholderWidth={323}
                sectionTitle={'Recent Fishing Reports Near You'}
                showLines={false}
                wrapperClassName={styles.sectionHeading__recentMedia}
              />
            ) : (
              showRecentMediaCarousel && (
                <div className={styles.sectionHeader__recentMedia}>
                  <h3 className={styles.h3}>Recent Fishing Reports Near You</h3>
                  <MediaFishingReportsList
                    allFishingReportsCount={0}
                    fishingReports={recentMediaFishingReports}
                    preferProductTitle
                    showFooterCTA
                    showSeason={false}
                    showTechnique
                  />
                </div>
              )
            )}
          </>
        </ContentWrapper>
      </section>
      {!isLoggedIn && <CTABanners />}
      <ContentWrapper>
        <section className={styles.articlesSection}>
          <div className={styles.sectionHeader}>
            <h3 className={styles.h3}>Latest Videos</h3>
            <Link to={RoutePaths.FISHING_ARTICLES} className={styles.sectionSeeAll}>
              More Fishing Content ›
            </Link>
          </div>
          <ul className={styles.articles}>
            {mediaSliced.map((content) => (
              <li key={content.uid} className={styles.article}>
                <MediaCard media={content} imgWidth={427} />
              </li>
            ))}
          </ul>
        </section>
      </ContentWrapper>
      <section>
        <ContentWrapper>
          <HomeOmniaPro />
        </ContentWrapper>
      </section>

      <section>
        <div className={styles.backgroundSVGs}>
          {/* these svg's work with the clip paths .svgWave in the associated less file.
              they are connected using id's */}
          {SVGBackgroundClipPathDesktop}
          {SVGBackgroundClipPathMobile}
        </div>
        <div className={styles.videosBackground} ref={videoSectionRef}>
          <div className={styles.svgWave} />
          <HomePromoVideos videoSectionIntersecting={videoSectionIntersecting} />
        </div>
        <div className={classNames(styles.svgWave, styles.svgWave__bottom)} />
      </section>

      <ContentWrapper>
        {!isLoggedIn && <HomePromoPanels />}
        <ContentWrapper className={styles.statesMapSection} noPadding>
          <div className={styles.sectionHeader}>
            <h3 className={styles.h3}>Shop By State</h3>
          </div>
          <div className={styles.statesMapContainer}>
            <USStatesMapSelect />
          </div>
        </ContentWrapper>

        <section className={styles.ambassadorSection}>
          <div className={styles.sectionHeader}>
            <h3 className={styles.h3}>
              <Link to={RoutePaths.AMBASSADORS}></Link>
              Omnia Ambassadors
            </h3>
            <Link to={RoutePaths.AMBASSADORS} className={styles.sectionSeeAll}>
              See all Ambassadors ›
            </Link>
          </div>
          <ol className={styles.ambassadorList}>
            {ambassadors.map((ambassador) => (
              <li
                className={styles.ambassadorListItem}
                key={ambassador.slug}
                onClick={() =>
                  WebAnalytics.homepageContentClick(
                    '[homepage].(ambassador)',
                    `${RoutePaths.USERS}/${ambassador.slug}`
                  )
                }
              >
                <UserProfileCard profile={ambassador} />
              </li>
            ))}
          </ol>
        </section>
      </ContentWrapper>
    </>
  );
};
